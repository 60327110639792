import { toast } from 'react-toastify';
import axios from 'axios';

class ServiceOnboarding {
    constructor() {
        //this.IdentityToken = localStorage.getItem('Identitytoken');
        this.IdentityToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6IkZEbFpBN3loY0tNUUhiVmk5NDdfckEiLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE3MzE1MDcxMTMsImV4cCI6MTczMTUxMDcxMywiaXNzIjoiaHR0cHM6Ly91bml0ZWFtLmlvL2FwcGlzIiwiYXVkIjoiYWxsIiwiY2xpZW50X2lkIjoiWTlZUVQ5QkM2SDhVWFZMTiIsInNjb3BlIjpbImFsbCJdfQ.RtnruzmUfhC0JKqC9A2QPwv7YdX6msEghA5T5vdo18WuEZ60q_Vkk2szXMS_f_CwtjDIh-XAgb5ojzTkVs_sKRePZW6sH02Q-sxnMZv-k9XJ4jD_w5o7TXBQBgiPoZDQF8DIO89opnnIhK5APjSGd2JoZblXEPR3cwEZ7t2cyqD4ZayBZjO3VJqq2IyfYDdnSSThlUCoiErnpgh6atK5wbjSigxn7TKqAXYi4ImxQ7mfU91M4W1jsI7Xg6EMzMKv-gm3bSrr8Q-MacAhoF_7JFJjnkoGm3DkIXXb-hV52czofaUKi49reAt6dVvRDvd8255MfSvdJGIpiH89a37kVw";
        this.ApiBaseURL_login = process.env.REACT_APP_LOGIN_URL;
        this.ApiBaseURL_Reg = process.env.REACT_APP_REGISTER_URL;
        this.ApibaseURL = process.env.REACT_APP_URL;
        this.AccessToken = localStorage.getItem('AccessToken');
        this.Email = localStorage.getItem('Email');
        this.ClientID = Number(localStorage.getItem('ClientID'));
        this.ipAddress = localStorage.getItem("IP_ADDRESS");
        this.items = [];        
    }

    async retrieveItems() {
        return Promise.resolve(this.items);
    }

    async setIPAddress() {
        localStorage.setItem("Identitytoken", "eyJhbGciOiJSUzI1NiIsImtpZCI6IkZEbFpBN3loY0tNUUhiVmk5NDdfckEiLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE3MzE1MDcxMTMsImV4cCI6MTczMTUxMDcxMywiaXNzIjoiaHR0cHM6Ly91bml0ZWFtLmlvL2FwcGlzIiwiYXVkIjoiYWxsIiwiY2xpZW50X2lkIjoiWTlZUVQ5QkM2SDhVWFZMTiIsInNjb3BlIjpbImFsbCJdfQ.RtnruzmUfhC0JKqC9A2QPwv7YdX6msEghA5T5vdo18WuEZ60q_Vkk2szXMS_f_CwtjDIh-XAgb5ojzTkVs_sKRePZW6sH02Q-sxnMZv-k9XJ4jD_w5o7TXBQBgiPoZDQF8DIO89opnnIhK5APjSGd2JoZblXEPR3cwEZ7t2cyqD4ZayBZjO3VJqq2IyfYDdnSSThlUCoiErnpgh6atK5wbjSigxn7TKqAXYi4ImxQ7mfU91M4W1jsI7Xg6EMzMKv-gm3bSrr8Q-MacAhoF_7JFJjnkoGm3DkIXXb-hV52czofaUKi49reAt6dVvRDvd8255MfSvdJGIpiH89a37kVw");
        
        try {
            const response = await axios.get('https://api.ipify.org?format=json'); // Get IP address
            const ip = response.data.ip;
            console.log("IP Address:", ip);
            localStorage.setItem("IP_ADDRESS", ip);
            this.ipAddress = ip;
        } catch (error) {
            console.error("Failed to fetch IP address:", error);
        }
    }

    async Login(req) {
        try {
            const dataset = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "Password": req.Password,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApiBaseURL_login}login`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                // localStorage.setItem('AccessToken', result.accessToken);
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async UserProfile(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };

            const response = await fetch(`${this.ApibaseURL}users/profile?email=${req.Email}&ipAddress=${this.ipAddress}`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                localStorage.setItem('FullName', result.fullName);
                localStorage.setItem('Email', result.email);
                localStorage.setItem('UserID', result.userId);
                localStorage.setItem('Profile', JSON.stringify(result));
                
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }
    
    async getClientUserProfile(memberemail) {
        const requestOptions = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('AccessToken'),
            },
        };

        const response = await fetch(
            `${this.ApibaseURL}clientusers/profile?viewbyemail=${localStorage.getItem('Email')}&ipAddress=${this.ipAddress}&memberemail=${memberemail}&clientid=${localStorage.getItem('ClientID')}`,
            requestOptions
        );
        return response;
    }

    async getNotification_api(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };
            const response = await fetch(`${this.ApibaseURL}notification/list?email=${req.Email}&ipAddress=${this.ipAddress}&clientid=${req.ClientID}&filter=All`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async markasread_api(req) {
        try {
            const dataset = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            };
            const response = await fetch(`${this.ApibaseURL}notification/markread?email=${req.Email}&ipAddress=${this.ipAddress}&clientid=${req.ClientID}&record=${req.record}&id=${req.id}`, dataset);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async RegisterUser(req) {
        try {
            const dataset = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "FullName": req.Name,
                    "Email": req.Email,
                    "Password": req.Password,
                    "SignupMode": "Web",
                    "IdentityToken": this.IdentityToken,
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApiBaseURL_Reg}register`, dataset);
            return response;        
           
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AcceptInvitaion(req) {        
            const dataset = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({                    
                    "Email": req.Email,
                   "ClientId": req.ClientId,
                })
            };
            const response = await fetch(`${this.ApibaseURL}clientusers/accept/invitation`, dataset);
            return response;     
    }

    async UploadProfile(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: req.formData
            };
            const response = await fetch(`${this.ApibaseURL}uploadprofileimage`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddCompanyInfo(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "CompanyName": req.CompanyName,
                    "JobTitle": req.JobTitle,
                    "Phone": req.Phone,
                    "CountryCode": req.CountryCode,
                    "STDCode": req.STDCode,
                    "TimeZoneID": req.TimeZoneID,
                    "ProfileImageURL": req.ProfileImageURL
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/companyinfo`, Request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddMemberInfo(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "CompanyName": req.CompanyName,
                    "JobTitle": req.JobTitle,
                    "Phone": req.Phone,
                    "CountryCode": req.CountryCode,
                    "STDCode": req.STDCode,
                    "TimeZoneID": req.TimeZoneID,
                    "ProfileImageURL": req.ProfileImageURL
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/memberinfo`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddOfficeTeams(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "ClientID": req.ClientID,
                    "OfficeTeams": req.officeteams
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/officeteams`, Request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async AddProjects(req) {
        try {
            const Request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "ClientID": req.ClientID,
                    "Projects": req.projects
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/projects`, Request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async FinishOnboarding(Email) {
        try {
            const request = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": Email,
                    "ActivePlan": {
                        "plan_code": "FREETRIAL"
                    }
                })
            };
            const response = await fetch(`${this.ApibaseURL}onboarding/finish`, request);
            return response;
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async Masterlist() {       
        const dataset = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
            }
        };
        const response = await fetch(`${this.ApibaseURL}masterlist`, dataset);
        return response;
    }

    async ResetPasswordEmail(Email) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": Email,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=getotp`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
                return result;
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ResetPasswordValidateOTP(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "OTP": req.otp,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=validateotp`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
                 
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ResetPassword(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "NewPassword": req.Password,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApibaseURL}users/resetpassword?action=changepassword`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }

    async ChangePassword(req) {
        try {
            const request = {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: JSON.stringify({
                    "Email": req.Email,
                    "NewPassword": req.NewPassword,
                    "OldPassword": req.OldPassword,
                    "IdentityToken": this.IdentityToken,
                    "Device": "Web",
                    "IPAddress": this.ipAddress
                })
            };
            const response = await fetch(`${this.ApibaseURL}settings/changepassword`, request);
            if (response.status === 401) {
                toast.error('Your Session has expired, Please login again.');
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 1000);
                return;
            }
            const result = await response.json();
            if (response.ok) {
                this.items = result;
                return result;
            } else if (response.status === 400) {
                toast.error(result.errors[0]);
            }
        } catch (error) {
            toast.error('Something went wrong, Please try again later.');
        }
    }
}

export default ServiceOnboarding;
