import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
const ProfileOptions = () => {

    const navigate = useNavigate();
    const userPhoto = localStorage.getItem('UserPhoto');
    const userFullName = localStorage.getItem('FullName');
    const userAltText = localStorage.getItem('alt_text');
    const userEmail = localStorage.getItem('Email');
    const portals = JSON.parse(localStorage.getItem("Portals"));


    const logout = () => {
        let ipaddress=localStorage.getItem("IP_ADDRESS");
        localStorage.clear();
        localStorage.setItem("IP_ADDRESS", ipaddress);
        navigate("/");
    }
    
    return (
        <div className="topbarProfile">
            <div className="dropdown">
                {(() => {
                    if (userPhoto !== null && userPhoto !== "" && userPhoto !== "null") {
                        return <a className="dropdown-toggle" data-toggle="dropdown"><span className="profile-img"><img src={userPhoto} title={userFullName} alt={userFullName} /></span></a>
                    }
                    else {
                        return <a className="dropdown-toggle" data-toggle="dropdown"><span className="profile-img"><span className="user-blank-uppr" title={userFullName}>{userAltText} </span></span></a>
                    }
                })()}
                <div className="dropdown-menu dropdown-menu-right my-dropdown profleddl" role="menu" style={{ left: "auto" }}>
                    <div className="userprofile">
                        <div className="profile-dtl" style={{ cursor: "default" }}>
                            <div className="profilebox">
                                <div className="profilepic">
                                    {(() => {
                                        if (userPhoto !== null && userPhoto !== "" && userPhoto !== "null") {
                                            return <img src={userPhoto} title={userFullName} alt={userFullName} />
                                        }
                                        else {
                                            return <span className="user-blank-uppr" title={userFullName}>{userAltText} </span>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="profile-name">{userFullName}</div>
                            <div className="profile-mail">{userEmail}</div>
                        </div>
                        <div className="link-sec">
                            <p><Link to="/settings/profile-setting"><img src="/images/icons-v2/manage-account.svg" alt="" />Manage Your Account </Link></p>
                            {portals && portals.length > 1 && <p><Link to="/portals"><img src="/images/setting/switch-portal.svg" alt="" />Switch Portal</Link></p>}
                            <p><Link to="https://uniteam.ai/help-center" target='_blank'><img src="/images/icons-v2/help.svg" alt="" />Help and Support </Link></p>
                            <p className='last'><a onClick={logout}><img src="/images/icons-v2/signout.svg" alt="" />Sign Out</a></p>
                        </div>
                        {/* <div className="gift-sec">
                            <div className="gift-text">
                                <h5>Enjoying Uniteam?</h5>
                                <p>Refer Uniteam to your friends and earn Amazon Pay Giftcards worth $20</p>
                            </div>
                            <div className="giftpic"><img src="/images/icons-v2/gift.svg" alt="uniteamImage" className="giftimg" /></div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default React.memo(ProfileOptions);