import axios from "axios";

export default class UserService {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_URL,
            AccessToken: localStorage.getItem('AccessToken'),
            ViewByEmail: localStorage.getItem('Email'),
            ClientID: Number(localStorage.getItem('ClientID')),
            ipAddress: localStorage.getItem("IP_ADDRESS"),
        };
        //this.setIPAddress();
    }

    async setIPAddress() {
        try {
            const response = await axios.get('https://api.ipify.org?format=json'); // Get IP address
            const ip = response.data.ip;
            console.log("IP Address:", ip);
            localStorage.setItem("IP_ADDRESS", ip);
            this.ipAddress = ip;
        } catch (error) {
            console.error("Failed to fetch IP address:", error);
        }
    }

    async getProfile(memberemail) {
        const response = await axios.get(`${this.state.ApibaseURL}clientusers/profile?viewbyemail=${this.state.ViewByEmail}&ipAddress=${this.state.ipAddress}&memberemail=${memberemail}&clientid=${this.state.ClientID}`, {
            headers: {
                Authorization: "Bearer " + this.state.AccessToken,
            }
        });
        return response;
    }

    async getAllClientUsers() {
        const response = await axios.get(`${this.state.ApibaseURL}clientusers/list?email=${this.state.ViewByEmail}&ipAddress=${this.state.ipAddress}&clientid=${this.state.ClientID}`, {
            headers: {
                Authorization: "Bearer " + this.state.AccessToken,
            },
        });
        return response;
    }

    async inviteMembers(emails) {

        const emaillist = emails.map((item) => ({ Email: item }));

        const response = await axios.post(
            `${this.state.ApibaseURL}clientusers/add`,
            {
                AddedBy: this.state.ViewByEmail,
                ClientID: this.state.ClientID,
                Users: emaillist,
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.state.AccessToken,
                },
            }
        );
        return response;

    }

    //for update user
    async updateUser(data) {
        const response = await axios.post(
            `${this.state.ApibaseURL}Clientusers/update`,
            data,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.state.AccessToken,
                },
            }
        );
        return response;
    }

    async getTimezone() {
        const response = await axios.get(`${this.state.ApibaseURL}masterlist`, {
            headers: {
                Authorization: "Bearer " + this.state.AccessToken,
            },
        });
        return response;

    }

    async removeMember(userid) {
        const response = await axios.post(
            `${this.state.ApibaseURL}clientusers/delete`,
            {
                DeletedBy: this.state.ViewByEmail,
                ClientID: this.state.ClientID,
                UserID: Number(userid),
            },
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.state.AccessToken,
                },
            }
        );
        return response;

    }

    //for upload picture
    // const handleImageUpload = async (e) => {
    //     try {
    //         const file = e.target.files[0];
    //         if (!file) return;

    //         const formData = new FormData();
    //         const filename = `profile_${Date.now()}.${file.name.split('.').pop()}`;
    //         formData.append("email", email);
    //         formData.append("clientid", ClientID);
    //         formData.append("title", "Profile Image");
    //         formData.append("profileimg", file, filename);

    //         const response = await fetch(`${process.env.REACT_APP_URL}uploadprofileimage`, {
    //             method: "POST",
    //             headers: {
    //                 'Authorization': `Bearer ${AccessToken}`
    //             },
    //             body: formData
    //         });

    //         if (response.ok) {
    //             const result = await response.json();
    //             setMemberData(prevState => ({
    //                 ...prevState,
    //                 profileImage: result.profileimg_url,
    //             }));
    //         } else {
    //             const result = await response.json();
    //             toast.error(result.errors || "Failed to upload image. Please try again later.");
    //         }
    //     } catch (error) {
    //         toast.error("An error occurred during image upload. Please try again later.");
    //     }
    // };

    async uploadProfileImage(data) {
        const response = await axios.post(
            `${this.state.ApibaseURL}uploadprofileimage`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${this.state.AccessToken}`,
                    'Content-Type': 'multipart/form-data'
                }

            }
        );
        return response;

    }


}