import * as signalR from '@microsoft/signalr';

const connectionUrl = 'https://api.uniteam.ai/chat';
    
let connection = new signalR.HubConnectionBuilder()
    .withUrl(connectionUrl, {})
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

const startConnection = (clientId, userId) => {
    if (connection.state !== signalR.HubConnectionState.Disconnected)
        return;
    connection.start()
        .then(() => {
            //when connected, send invoke to join the group
            connection.invoke('JoinGroup', clientId, userId)
                .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
}

const stopConnection = async () => {
    if (connection === null)
        return;
    console.log('Stopping connection', connection.state);
    if (connection.state === signalR.HubConnectionState.Connected)
        await connection.stop();
}

const invoke = async (methodName, ...args) => {
    if (connection === null)
        return;
    if (connection.state === signalR.HubConnectionState.Disconnected)
        await connection.start();

    await connection.invoke(methodName, ...args);
}

const subscribeEvent = (eventName, callback) => {
    if (connection === null)
        return;
    connection.on(eventName, callback);
}

export { startConnection, stopConnection, invoke, subscribeEvent };