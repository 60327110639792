import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ServiceOnboarding from '../services/ServiceOnboarding';

function Sidenavbar() {
    const role = localStorage.getItem('UserType');
    const allowAnalytics = JSON.parse(localStorage.getItem('LoginUserInfo'))?.allowanalytics || false;
    const [Modules, setModules] = useState([]);
    const [activeMenu, setActiveMenu] = useState("");
    const apifunction = new ServiceOnboarding();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.replace(/\/$/, "");

    useEffect(() => {
        if (!localStorage.getItem('UserID')) {
            navigate("/");
        } else {
            if (pathname === "/") setActiveMenu("tasks");
            else if (pathname.match(/tasks/)) setActiveMenu("tasks");
            else if (pathname.match(/teams/)) setActiveMenu("teams");
            else if (pathname.match(/meetings/)) setActiveMenu("meetings");
            else if (pathname.match(/analytics/) || pathname.match(/engagement/)) {
                //if (!hasAccessToModule("Performance Analytics") && !hasAccessToModule("Engagement Analytics")) {
                   // navigate("/unauthorized");
                //} else {
                    setActiveMenu("analytics");
                //}
            }
            else if (pathname.match('settings/profile-setting')) {
                setActiveMenu("profile-setting");
            }
            else if (pathname.match(/project-tasks/)) setActiveMenu("projects");
        }
    }, [pathname, navigate]);

    useEffect(() => {
        const loadData = async () => {
            if (window.performance && performance.navigation.type === 1) {
                await loadModules();
            }
        };

        loadData();
    }, [pathname]);

    useEffect(() => {
        if (Modules.length > 0) {
            const checkIfDataAvailable = (module_name) => {
                return Modules.some(item => item.module_name === module_name);
            };

            if (pathname === "/project/all" && !checkIfDataAvailable("Projects")) {
                navigate("/please-upgrade-plan");
            } else if (pathname === "/analytics" && !checkIfDataAvailable("Performance Analytics")) {
                navigate("/please-upgrade-plan");
            } else if (pathname.match("/engagement/") && !checkIfDataAvailable("Engagement Analytics")) {
                navigate("/please-upgrade-plan");
            }
        }
    }, [pathname, Modules, navigate]);

    const loadModules = async () => {
        try {
            const req = {
                Email: localStorage.getItem("Email")
            };

            const result = await apifunction.UserProfile(req);
            if (result) {
                const selectedPortalIndex = Number(localStorage.getItem("selectedPortalIndex"));
                const selectedPortal = result.portals[selectedPortalIndex];
                console.log("modules", selectedPortal.plan.modules);
                if (selectedPortal) {
                    localStorage.setItem("Totalmembers", selectedPortal.totalMembers);
                    localStorage.setItem("offsettolocal", selectedPortal.offsettolocal);
                }
                if (selectedPortal.plan?.modules?.length > 0) {
                    setModules(selectedPortal.plan.modules);
                } else {
                    toast.error("You don't have permissions to access any module. Please contact admin.");
                    setTimeout(() => {
                        localStorage.clear();
                        navigate("/");
                    }, 1000);
                }
            }
        } catch (error) {
            toast.error('Failed to load modules. Please try again later.');
        }
    };

    // const hasAccessToModule = (moduleName) => {
    //     return Modules.some((item) => item.module_name.includes(moduleName));
    // };

    return (
        <div className="fiexdsidenav" style={{ overflow: 'hidden' }}>
            <div className="logo" onClick={() => navigate("/")}>
                <img alt="image" src="/images/icons-v2/uniteam-Icon-white.svg" className="inner-logo" />
            </div>
            <div className="menu-list">
                {Modules && Modules.length > 0 ? Modules.map((item, index) => (
                    <div key={index}>
                        {item.module_name === "Tasks" && (
                            <Link id={index} className={activeMenu === "tasks" ? "active" : ""} to="/tasks">
                                <img alt="" src="/images/icons-v2/sidebar-tasks2.svg" className="menu-icon" />Tasks
                            </Link>
                        )}
                        {item.module_name === "Meetings" && (
                            <Link id={index} className={activeMenu === "meetings" ? "active" : ""} to="/meetings">
                                <img alt="" src="/images/icons-v2/sidebar-meetings2.svg" className="menu-icon" />Meetings
                            </Link>
                        )}
                        {item.module_name === "Teams" && (
                            <Link id={index} className={activeMenu === "teams" ? "active" : ""} to={`/teams`}>
                                <img alt="" src="/images/icons-v2/sidebar-teams2.svg" className="menu-icon" />Teams
                            </Link>
                        )}
                        {item.module_name === "Performance Analytics" && role === "Admin" && (
                            <Link id={index} className={activeMenu === "analytics" ? "active" : ""} to="/analytics">
                                <img alt="" src="/images/icons-v2/sidebar-analytics2.svg" className="menu-icon" />Analytics
                            </Link>
                        )}
                        {item.module_name === "Engagement Analytics" && role === "Admin" && (
                            <Link id={index} className={activeMenu === "profile-setting" ? "active" : ""} to="/settings/subscription">
                                <img alt="" src="/images/icons-v2/settings-sliders2.svg" className="menu-icon" />
                                Settings
                            </Link>
                        )}
                    </div>
                )) : (
                    <>
                        <Link to="/tasks" className={activeMenu === "tasks" ? "active" : ""}>
                            <img alt="" src="/images/icons-v2/sidebar-tasks2.svg" className="menu-icon" />Tasks
                        </Link>
                        <Link to="/meetings" className={activeMenu === "meetings" ? "active" : ""}>
                            <img alt="" src="/images/icons-v2/sidebar-meetings2.svg" className="menu-icon" />Meetings
                        </Link>
                        <Link to={`/teams`} className={activeMenu === "teams" ? "active" : ""}>
                            <img alt="" src="/images/icons-v2/sidebar-teams2.svg" className="menu-icon" style={{ width: "22px" }} />Teams
                        </Link>
                        {(role === "Admin") && (
                            <Link to="/analytics" className={activeMenu === "analytics" ? "active" : ""}>
                                <img alt="" src="/images/icons-v2/sidebar-analytics2.svg" className="menu-icon" style={{ width: "22px" }} />Analytics
                            </Link>
                        )}
                        {role === "Admin" && (
                            <Link to="/settings/subscription" className={activeMenu === "profile-setting" ? "active" : ""}>
                                <img alt="" src="/images/icons-v2/settings-sliders2.svg" className="menu-icon" style={{ width: "22px" }} />
                                Settings
                            </Link>)}
                    </>
                )}
            </div>
        </div>
    );
}

export default React.memo(Sidenavbar);
