import React from "react";
import { useNavigate } from "react-router-dom";
const SuperAdminHeader = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <div className="setting-header hadmin  d-flex justify-content-between align-items-center sadmin ">
        <div className="d-flex ">
          <h4 className="pull-left ms-0">
          
            <a class="navbar-brand d-flex">
              <img alt="image" src="/images/icons-v2/uniteam-Logo-new.svg" class="unilogo" />
              </a>
          </h4>
        </div>
        <div className="pull-right rightside-menu me-2">
          <div className="topbarProfile">
            <div className="dropdown">
              <a className="dropdown-toggle" data-toggle="dropdown">
                <span className="profile-img">
                  <img src="/images/user.jpg" alt="User" />
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right my-dropdown profleddl p-2"
                role="menu"
                style={{ left: "auto" }}
              >
                {/* <a href="/superadmin/profile" className="dropdown-item">
                  <i className="fa fa-user"></i> Profile
                </a>
                <a href="/superadmin/change-password" className="dropdown-item">
                  <i className="fa fa-key"></i> Change Password
                </a> */}
                <a onClick={handleLogout} className="dropdown-item">
                  <i className="fa fa-sign-out"></i> Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHeader;
