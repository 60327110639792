import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ServiceOnboarding from "../../services/ServiceOnboarding";
import { toast } from "react-toastify";
import { isOnline } from "../../functions/commonFunctions";

const AcceptInvitation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const apifunction = new ServiceOnboarding();
  const [isFetching, setIsFetching] = useState(false);

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get("clientId");
  const email = queryParams.get("email");

  useEffect(() => {
    if (!isOnline()) {
      toast.error("No Internet Connection.");
    }
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    try {
      const req = {
        Email: email,
        ClientId: clientId,
      };
      const response = await apifunction.AcceptInvitaion(req);
      const result = await response.json();
      if (response.ok) {
        toast.success("Invitation accepted successfully.");
        navigate("/login");
      } else if (result?.errors?.length > 0) {
        toast.error(result.errors[0]);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong, Please try again.");
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="onboarding login">
      <div className="d-flex justify-content-between onboarding-top-header align-items-center">
        <div className="logo mt-0">
          <a className="navbar-brand mt-0 pt-0" onClick={() => navigate("/")}>
            <img
              alt="image"
              src="images/icons-v2/uniteam-Logo-white-new.svg"
              width="150px"
            />
          </a>
        </div>
        <div className="closebtn">
          <Link to="/">
            <img alt="uniteamImage" src="images/icons/onbording-cross.svg" />
          </Link>
        </div>
      </div>

      <div className="login-right-pnl">
        <div className="onboarding-box">
          <div className="top-title sign-top">
            <div className="line2">Accept Invitation</div>
            <div className="heading-underline"></div>
          </div>
          <form className="loginform" onSubmit={submitForm}>
            <div className="mt-38px">
              <button
                type="submit"
                className="btn btn-primary btn-lg btn170"
                disabled={isFetching}
              >
                {isFetching && (
                  <span id="spinner">
                    <i className="fa fa-spinner fa-spin"></i>
                  </span>
                )}
                Accept Invitation
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default AcceptInvitation;
